import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { get, post, put, putPresigned, upload } from "../../services/API/ApiClient";
import { RootState } from "../Store";
import { batch } from "react-redux";

//Fetch Brands
export const fetchBrandsImageCollection = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchBrandsImageCollection", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const previousBatchId = getState().imageCollectionState.currentBatchId;
    const reqBody = {
      ...getState().imageCollectionState.currentRequestBody,
    };
    const responseBatchPickList = await get("/batches", "logoCloudImageCollection");
    const batchPickList = responseBatchPickList.data.map((item: any) => ({ value: item.id, label: item.name }));
    if (batchPickList.find((item: any) => item.value === previousBatchId)) {
      const response = await post("/search", "logoCloudImageCollection", reqBody);
      dispatch(setBatchPickListForImageCollection(batchPickList));
      const currentBatchId = response.data.data[0] ? response.data.data[0].batchId : getState().imageCollectionState.currentBatchId;
      dispatch(setCurrentBatchIdForImageCollection(currentBatchId));
      return response.data;
    } else {
      const newReqBody = { ...reqBody, batchId: batchPickList[0].value };
      dispatch(setCurrentBatchIdForImageCollection(batchPickList[0].value));
      dispatch(updateRequestBodyForImageCollection(newReqBody));
      const response = await post("/search", "logoCloudImageCollection", newReqBody);
      dispatch(setBatchPickListForImageCollection(batchPickList));
      const currentBatchId = response.data.data[0] ? response.data.data[0].batchId : getState().imageCollectionState.currentBatchId;
      dispatch(setCurrentBatchIdForImageCollection(currentBatchId));
      return response.data;
    }
  } catch (e) {
    return rejectWithValue(e);
  }
});

//Infinite Scrolling Brands
export const fetchPaginateBrandsImageCollection = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchPaginateBrandsImageCollection", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().imageCollectionState.currentRequestBody,
      pageNum: getState().imageCollectionState.pagination.page,
    };
    const response = await post("/search", "logoCloudImageCollection", reqBody);

    return {
      data: response.data.data,
      hasMore: response.data.data.length > 0,
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});

//Search Brands
export const searchImageCollectionBrands = createAsyncThunk<any, { searchKeyword: string }, { state: RootState }>("logoProduction/searchImageCollectionBrands", async (args, { getState, dispatch }) => {
  dispatch(resetImageCollectionTable());
  dispatch(updateRequestBodyForImageCollection({ ...getState().imageCollectionState.currentRequestBody, keyword: args.searchKeyword }));

  dispatch(fetchBrandsImageCollection({}));
});

//Fetch Auto Refresh
export const fetchAutoRefreshImageCollection = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchAutoRefreshImageCollection", async (args, { getState, dispatch, rejectWithValue }) => {
  const currentDataLength = getState().imageCollectionState.data.length === 0 ? 30 : getState().imageCollectionState.data.length;

  try {
    const reqBody = {
      ...getState().imageCollectionState.currentRequestBody,
      pageSize: Math.ceil(currentDataLength / 30) * 30,
    };
    //For batch picklist
    const responseBatchPickList = await get("/batches", "logoCloudImageCollection");
    const batchPickList = responseBatchPickList.data.map((item: any) => ({ value: item.id, label: item.name }));
    dispatch(setBatchPickListForImageCollection(batchPickList));

    const response = await post("/search", "logoCloudImageCollection", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

//Autorefresh
export const imageCollectionAutoRefresh = createAsyncThunk<any, any, { state: RootState }>("logoProduction/imageCollectionAutoRefresh", async (args, { getState, dispatch, rejectWithValue }) => {
  if (getState().imageCollectionState.autoRefresh.currentIntervalId) {
    clearInterval(getState().imageCollectionState.autoRefresh.currentIntervalId);
  }
  if (getState().imageCollectionState.autoRefresh.autoRefresh) {
    const intervalId = setInterval(() => {
      dispatch(fetchAutoRefreshImageCollection({}));
    }, 5000);
    dispatch(setAutoRefreshForImageCollection({ intervalId }));
  }
});

export const exportImageCollection = createAsyncThunk<any, any, { state: RootState }>("logoProduction/exportImageCollection", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await get(`/batches/${args.batchId}/export`, "logoCloudImageCollection");
    const downloadLink = response.data.preSignedUrl;

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", "");
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const requestBrand = createAsyncThunk<any, any, { state: RootState }>("logoProduction/requestBrand", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await post("/upload-initiate", "logoCloudImageCollection", args.uploadRequestBody);
    await upload(response.data.preSignedUrl, args.file);
    if (args.uploadRequestBody.isManualUpload) {
      await post("/upload-complete", "logoCloudImageCollection", {
        BatchId: response.data.batchId,
        NextNode: "MetadataRequestor",
      });
    } else {
      await post("/upload-complete", "logoCloudImageCollection", {
        BatchId: response.data.batchId,
        NextNode: "HarvestRequestor",
      });
    }
  } catch (error: any) {
    const errorResponse = error?.response.data.detailedMessages;
    return rejectWithValue(errorResponse);
  }
});

export const fetchTenantPickList = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchTenantPickList", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await get("/ref-data/pick-list/tenants", "logoCloud");
    const pickList = response.map((item: any) => ({ value: item.key, label: item.value }));
    return pickList;
    // return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

//Start the generic action for ImageCollection
export const setBatchPickListForImageCollection = createAction<any>("logoProduction/setBatchPickListForImageCollection");
export const updateRequestBodyForImageCollection = createAction<any>("logoProduction/updateRequestBodyForImageCollection");
export const resetImageCollectionTable = createAction("logoProduction/resetImageCollectionTable");
export const setCurrentBatchIdForImageCollection = createAction<number>("logoProduction/setCurrentBatchIdForImageCollection");
export const setAutoRefreshForImageCollection = createAction<{ intervalId: NodeJS.Timer }>("logoProduction/setAutoRefreshForImageCollection");
export const switchAutoFreshForImageCollection = createAction<{ autoRefresh: boolean }>("logoProduction/switchAutoFreshForImageCollection");
export const stopAutoRefreshForImageCollection = createAction("logoProduction/stopAutoRefreshForImageCollection");
export const clearDetailMessageofBrandRequestModal = createAction("logoProduction/clearDetailMessageofBrandRequestModal");
//End of generic action for ImageCollection

//Modal
export const openBrandRequestModal = createAction("logoProduction/openBrandRequestModal");
export const closeBrandRequestModal = createAction("logoProduction/closeBrandRequestModal");
export const openBrandRequestSuccessModal = createAction("logoProduction/openBrandRequestSuccessModal");
export const closeBrandRequestSuccessModal = createAction("logoProduction/closeBrandRequestSuccessModal");

//Start process log action

export const fetchProcessLog = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchProcessLog", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().imageCollectionState.processLogPageState.currentRequestBody,
    };
    const response = await post("/brands/process-logs", "logoCloudImageCollection", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const fetchPaginateProcessLog = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchPaginateProcessLog", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().imageCollectionState.processLogPageState.currentRequestBody,
      pageNum: getState().imageCollectionState.processLogPageState.pagination.page,
    };
    const response = await post("/brands/process-logs", "logoCloudImageCollection", reqBody);
    return {
      data: response.data.results,
      hasMore: response.data.results.length > 0,
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const searchProcessLog = createAsyncThunk<any, { searchKeyword: string }, { state: RootState }>("logoProduction/searchProcessLog", async (args, { getState, dispatch }) => {
  dispatch(resetProcessLogTable());
  dispatch(updateRequestBodyForProcessLog({ ...getState().imageCollectionState.processLogPageState.currentRequestBody, keyword: args.searchKeyword }));

  dispatch(fetchProcessLog({}));
});

export const resetProcessLogTable = createAction("logoProduction/resetProcessLogTable");
export const updateRequestBodyForProcessLog = createAction<any>("logoProduction/updateRequestBodyForProcessLog");

//Modal
export const processLogDetailsOpenModal = createAction("logoProduction/processLogDetailsOpenModal");
export const processLogDetailsCloseModal = createAction("logoProduction/processLogDetailsCloseModal");

export const getSelectedProcessLog = createAction<any>("logoProduction/getSelectedProcessLog");
