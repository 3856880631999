import { Button, Form, Input, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import ImageViewer from "../../ImageViewer/ImageViewer";
import AddStockSymbolModal from "../ModalComponent/AddStockSymbolModal";
import DeleteStockSymbolModal from "../ModalComponent/DeleteStockSymbolModal";
import EditStockSymbolModal from "../ModalComponent/EditStockSymbolModal";
import StockSymbolTable from "../TableComponent/StockSymbolTable";
import { StockSymbolTableColumns } from "../TableComponent/StockSymbolTableColumns";
import "./BrandQualityCheck.css";
import { validateDomain, validateISIN, validateNameLength } from "./BrandQualityCheckHelpers";
import { saveBrandDetailImageVerification, setIsEditedForImageVerification, openAddStockSymbolModalForImageVerification, closeAddStockSymbolModalForImageVerification, openEditStockSymbolModalForImageVerification, closeEditStockSymbolModalForImageVerification, openDeleteStockSymbolModalForImageVerification, closeDeleteStockSymbolModalForImageVerification, setStockSymbolsForImageVerification, setCurrentImageIndexImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";
import UploadImageComponent from "../UploadImageComponent/UploadImageComponent";


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 19 },
  },
};

function BrandQualityCheck(props: any) {
  const [uploadOpenState, setUploadOpenState] = useState(false);
  const [form] = Form.useForm();
  const brandDetailState = useSelector((state: RootState) => state.imageVerificationState.brandDetailState);
  const isEditing = brandDetailState.isEdited;
  const dispatch = useAppDispatch();
  const [descriptionCharCount, setDescriptionCharCount] = useState(brandDetailState.data?.description ? 4000 - brandDetailState.data.description.length : 4000);
  const pageType = "QC";
  const onFinish = (values: any) => {
    const brandLocation = {
      id:brandDetailState?.data?.brandLocation?.id,
      country: values.country,
      brandId:props.brandId,
    };
    dispatch(saveBrandDetailImageVerification({ brandDetail: { ...values, brandLocation: brandLocation }, brandId: props.brandId, batchId: props.batchId }));
    dispatch(setIsEditedForImageVerification(false));
  };

  useEffect(() => {
    form.setFieldsValue(brandDetailState.data);
    form.setFieldValue("country", brandDetailState?.data?.brandLocation?.country);
    const previousStockSymbols = brandDetailState.data.stockSymbols.filter((stockSymbol: any) => stockSymbol.id !== undefined);
    dispatch(setStockSymbolsForImageVerification(previousStockSymbols));
  }, [props.tabKey]);

  useEffect(() => {
    form.setFieldValue("stockSymbols", brandDetailState.data.stockSymbols);
  }, [brandDetailState.data.stockSymbols]);
  function openUpload() {
    setUploadOpenState(true);
  }
  function closeUpload() {
    setUploadOpenState(false);
  }
  return (
    <div
      className="d-flex h-100 flex-column flex-md-row"
      key={2}>
      <AddStockSymbolModal
        open={() => dispatch(openAddStockSymbolModalForImageVerification())}
        close={() => dispatch(closeAddStockSymbolModalForImageVerification())}
        openState={brandDetailState.openStockSymbolModalState}
      />
      <EditStockSymbolModal
        open={() => dispatch(openEditStockSymbolModalForImageVerification())}
        close={() => dispatch(closeEditStockSymbolModalForImageVerification())}
        openState={brandDetailState.openEditStockSymbolModalState}
      />
      <DeleteStockSymbolModal
        open={() => dispatch(openDeleteStockSymbolModalForImageVerification())}
        close={() => dispatch(closeDeleteStockSymbolModalForImageVerification())}
        openState={brandDetailState.openDeleteStockSymbolModalState}
      />
 <UploadImageComponent
  brandName={props.brandName}
    brandId={props.brandId}
    batchId={props.batchId}
        open={openUpload}
        close={closeUpload}
        openState={uploadOpenState}
      />
      <div className="form-container d-flex  justify-content-between   flex-column pe-0 pe-md-4  py-3 ">
        <div className="d-flex flex-column full-flex h-100 overflow-auto">
          <Spin
            spinning={brandDetailState.saveLoading}
            className="overflow-hidden">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              disabled={brandDetailState.data.status === "Completed"}
              colon={false}
              // style={{ maxWidth: 600 }}
              scrollToFirstError
              onValuesChange={() => {
                dispatch(setIsEditedForImageVerification(true));
              }}>
              <Form.Item
                className="form-item"
                name="name"
                label="Name"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide brand name.",
                  },
                  {
                    validator: validateNameLength,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                className="form-item"
                name="domain"
                label="Website"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide website.",
                  },
                  {
                    validator: (rule, value, callback) => validateDomain(rule, value, callback),
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                className="form-item"
                name="country"
                labelAlign="left"
                label={<span className="nonMandatoryInputFields">Country</span>}>
                <Select
                  placeholder="Please select country."
                  showSearch>
                  {brandDetailState.dropDownData.country.map((option, idx) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      <div>{option.label}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="tags"
                label={<span className="nonMandatoryInputFields">Tags</span>}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value && value.length > 0) {
                        const lastTag = value[value.length - 1].trim();
                        if (lastTag === "") {
                          callback("Please provide valid tags (Tags cannot end with empty spaces)");
                        } else {
                          callback();
                        }
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                labelAlign="left">
                <Select
                  mode="tags"
                  options={[]}
                  tokenSeparators={[",", ";"]}
                />
              </Form.Item>

              <Form.Item
                className="descriptionField"
                name="description"
                labelAlign="left"
                label={<span className="nonMandatoryInputFields">Description</span>}>
                <TextArea
                  onChange={() => {
                    setDescriptionCharCount(4000 - form.getFieldValue("description").length);
                  }}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  maxLength={4000}
                />
              </Form.Item>
              <div className="brandPageCharCount">{descriptionCharCount} remaining characters.</div>

              <Form.Item
                className="form-item"
                name="stockSymbols"
                labelAlign="left"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={<span className="nonMandatoryInputFields">Stock Symbols</span>}>
                <div
                  className="d-flex flex-column full-flex h-100 w-100"

                  // label="Stock Symbols"
                >
                  <StockSymbolTable
                    setIsEditing={props.setIsEditing}
                    brandStatus={brandDetailState.data.status}
                    dataSource={brandDetailState.data.stockSymbols}
                    columns={StockSymbolTableColumns}
                  />
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </div>

        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex gap-4">
            <Button
              onClick={() => form.submit()}
              className="saveButton "
              disabled={isEditing ? false : true}
              // disabled={brandDetailState.saveLoading}
              type="primary">
              Save
            </Button>
            <Button
              htmlType="reset"
              disabled={brandDetailState.saveLoading}
              onClick={() => {
                form.setFieldValue("country", brandDetailState.data?.brandLocation?.country);
                form.setFieldsValue(brandDetailState.data);
                const previousStockSymbols = brandDetailState.data.stockSymbols.filter((stockSymbol: any) => stockSymbol.id !== undefined);
                dispatch(setStockSymbolsForImageVerification(previousStockSymbols));
                setDescriptionCharCount(4000 - brandDetailState.data.description.length);
              }}>
              Clear
            </Button>
          </div>
        </div>
      </div>
      <div className="responsive-border d-flex flex-column full-flex h-100 pt-3 ps-0 ps-md-4 ">
        {props.images.length > 0 ? (
          <ImageViewer
            brandId={props.brandId}
            batchId={props.batchId}
            brandDetailState={brandDetailState}
            setCurrentImageIndex={(index: number) => dispatch(setCurrentImageIndexImageVerification(index))}
            pageType={pageType}
            images={props.images}
            id_key={"0"}
            visible={true}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <p className="imageNotFoundMsg">No images available</p>
          </div>
        )}
         <div className="d-flex align-items-center py-3 gap-2">
              {/* <Switch />
              <span>Select All</span> */}
              {/* <div className="link downloadImageLink">Download Image</div> */}
              <Button
              className="saveButton"
              onClick={() => openUpload()}
              disabled={props.isLocked || brandDetailState.data.status === "Completed"}
              type="primary"
            >
              Upload Image
            </Button>
            </div>
      </div>
    </div>
  );
}

export default BrandQualityCheck;
