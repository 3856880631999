import { combineReducers, configureStore } from "@reduxjs/toolkit";
import LogoProductionSlice from "./LogoProduction/LogoProductionSlice";
import updateStockSymbolReducer from "./LogoProduction/updateStockSymbolSlice";
import ImageVerificationSlice from "./ImageVerification/ImageVerificationSlice";
import ChatAISlice from "./ChatAI/ChatAISlice";
import LivePlatformSlice from "./LivePlatform/LivePlatformSlice";
import ImageCollectionSlice from "./ImageCollection/ImageCollectionSlice";

import TenantsSlice from "./Tenants/TenantSlice";
// import { logger } from "redux-logger";

export const rootReducer = combineReducers({ logoProductionState: LogoProductionSlice, updateStockSymbolState: updateStockSymbolReducer, imageVerificationState: ImageVerificationSlice, ChatAIState: ChatAISlice, livePlatformState: LivePlatformSlice, imageCollectionState: ImageCollectionSlice, TenantsState: TenantsSlice });
export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({ reducer: rootReducer });
export type AppDispatch = typeof store.dispatch;
export default store;
