import { createSlice } from "@reduxjs/toolkit";
import { clearDetailMessageofBrandRequestModal, closeBrandRequestModal, closeBrandRequestSuccessModal, exportImageCollection, fetchAutoRefreshImageCollection, fetchBrandsImageCollection, fetchPaginateBrandsImageCollection, fetchPaginateProcessLog, fetchProcessLog, fetchTenantPickList, getSelectedProcessLog, openBrandRequestModal, openBrandRequestSuccessModal, processLogDetailsCloseModal, processLogDetailsOpenModal, requestBrand, resetImageCollectionTable, resetProcessLogTable, setAutoRefreshForImageCollection, setBatchPickListForImageCollection, setCurrentBatchIdForImageCollection, stopAutoRefreshForImageCollection, switchAutoFreshForImageCollection, updateRequestBodyForImageCollection, updateRequestBodyForProcessLog } from "./ImageCollectionAction";
import { DefaultImageCollectionRequestBody, defaultProcessLogRequestBodyType, defaultImageCollectionRequestBody, defaultProcessLogRequestBody } from "../../constant/logoProductionType";

interface ImageCollectionStateType {
  data: any;
  loading: boolean;
  requestBrandLoading: boolean;
  error: any;
  detailedMessages: any;
  autoRefresh: AutoRefreshStateType;
  pagination: PaginationType;
  tenantPickList: any[];
  batchPickList: any;
  currentBatchId: number;
  currentRequestBody: DefaultImageCollectionRequestBody;
  brandRequestModalOpenState: boolean;
  brandRequestSuccessModalOpenState: boolean;
  processLogPageState: {
    loading: boolean;
    error: any;
    data: any;
    currentRequestBody: defaultProcessLogRequestBodyType;
    pagination: PaginationType;
    processLogModalState: boolean;
    processLogDetail: {
      data: any;
      brandID: number;
      stage: string;
      createdAt: string;
      message: string;
      description: string;
    };
  };
  exportLoading: boolean;
}
export interface PaginationType {
  page: number;
  pageSize: number;
  hasMore: boolean;
  loading: boolean;
}
interface AutoRefreshStateType {
  autoRefresh: boolean;
  currentIntervalId: NodeJS.Timer | undefined;
  error: string | undefined;
  loading: boolean;
}

const initialState: ImageCollectionStateType = {
  data: [],
  tenantPickList: [],
  loading: false,
  requestBrandLoading: false,
  error: null,
  detailedMessages: "",
  currentBatchId: 1,

  batchPickList: [],
  autoRefresh: {
    autoRefresh: false,//Auto refresh False now need to change in future to true
    currentIntervalId: undefined,
    error: undefined,
    loading: false,
  },
  pagination: {
    page: 2,
    pageSize: 30,
    hasMore: true,
    loading: false,
  },
  currentRequestBody: defaultImageCollectionRequestBody,
  brandRequestModalOpenState: false,
  brandRequestSuccessModalOpenState: false,
  processLogPageState: {
    loading: false,
    error: null,
    data: [],
    currentRequestBody: defaultProcessLogRequestBody,
    processLogModalState: false,
    pagination: {
      page: 2,
      pageSize: 30,
      hasMore: true,
      loading: false,
    },
    processLogDetail: {
      brandID: 0,
      stage: "",
      createdAt: "",
      message: "",
      description: "",
      data: [],
    },
  },
  exportLoading: false,
};
const ImageCollectionSlice = createSlice({
  name: "logoProduction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Fetch Brands
      .addCase(fetchBrandsImageCollection.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchBrandsImageCollection.fulfilled, (state, action) => {
        state.data = action.payload.data;

        state.loading = false;
      })
      .addCase(fetchBrandsImageCollection.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      //Infinite Scrolloing  Brands
      .addCase(fetchPaginateBrandsImageCollection.pending, (state, action) => {
        state.pagination.loading = true;
      })
      .addCase(fetchPaginateBrandsImageCollection.fulfilled, (state, action) => {
        state.pagination.page = state.pagination.page + 1;
        state.data = state.data.concat(action.payload.data);
        state.pagination.hasMore = false;
        state.pagination.loading = false;
      })

      .addCase(fetchPaginateBrandsImageCollection.rejected, (state, action) => {
        state.error = action.error.message;
        state.pagination.loading = false;
      })

      //Reset Brands
      .addCase(resetImageCollectionTable, (state, action) => {
        return {
          ...initialState,
          data: state.data,
          loading: state.loading,
          currentRequestBody: state.currentRequestBody,
          currentBatchId: state.currentBatchId,
          batchPickList: state.batchPickList,
          autoRefresh: { ...state.autoRefresh },
        };

        // state = {
        //   ...state,
        //   data: state.data,
        //   currentRequestBody: state.currentRequestBody,
        //   batchPickList: state.batchPickList,
        //   autoRefresh: { ...state.autoRefresh },
        //   currentBatchId: state.currentBatchId,
        // };
      })
      .addCase(setCurrentBatchIdForImageCollection, (state, action) => {
        state.currentBatchId = action.payload;
      })

      .addCase(requestBrand.pending, (state, action) => {
        state.requestBrandLoading = true;
      })
      .addCase(requestBrand.fulfilled, (state, action) => {
        state.requestBrandLoading = false;
      })
      .addCase(requestBrand.rejected, (state, action) => {
        state.error = action.error.message;
        state.detailedMessages = action.payload;
        state.requestBrandLoading = false;
      })

      //Set BrandPickList
      .addCase(setBatchPickListForImageCollection, (state, action) => {
        state.batchPickList = action.payload;
      })

      //Update BrandBody
      .addCase(updateRequestBodyForImageCollection, (state, action) => {
        state.currentRequestBody = action.payload;
      })

      //Fetch AutoRefresh Brands
      .addCase(fetchAutoRefreshImageCollection.pending, (state, action) => {
        state.autoRefresh.loading = true;
      })
      .addCase(fetchAutoRefreshImageCollection.fulfilled, (state, action) => {
        state.data = action.payload.data.length < state.data.length ? state.data : action.payload.data;
        state.autoRefresh.loading = false;
      })
      .addCase(fetchAutoRefreshImageCollection.rejected, (state, action) => {
        state.error = action.error.message;
        state.autoRefresh.loading = false;
      })

      .addCase(switchAutoFreshForImageCollection, (state, action) => {
        state.autoRefresh.autoRefresh = action.payload.autoRefresh;
      })
      .addCase(setAutoRefreshForImageCollection, (state, action) => {
        state.autoRefresh.currentIntervalId = action.payload.intervalId;
      })
      .addCase(stopAutoRefreshForImageCollection, (state, action) => {
        clearInterval(state.autoRefresh.currentIntervalId);
      })

      .addCase(fetchTenantPickList.pending, (state, action) => {
        state.requestBrandLoading = true;
      })
      .addCase(fetchTenantPickList.fulfilled, (state, action) => {
        state.tenantPickList = action.payload;
        state.requestBrandLoading = false;
      })
      .addCase(fetchTenantPickList.rejected, (state, action) => {
        state.error = action.error.message;
        state.requestBrandLoading = false;
      })

      //Modal Brands
      .addCase(openBrandRequestModal, (state, action) => {
        state.brandRequestModalOpenState = true;
      })
      .addCase(closeBrandRequestModal, (state, action) => {
        state.brandRequestModalOpenState = false;
      })
      .addCase(openBrandRequestSuccessModal, (state, action) => {
        state.brandRequestSuccessModalOpenState = true;
      })
      .addCase(closeBrandRequestSuccessModal, (state, action) => {
        state.brandRequestSuccessModalOpenState = false;
      })

      //Process log action reducer
      .addCase(fetchProcessLog.pending, (state, action) => {
        state.processLogPageState.loading = true;
      })
      .addCase(fetchProcessLog.fulfilled, (state, action) => {
        state.processLogPageState.data = action.payload.results;
        state.processLogPageState.loading = false;
      })
      .addCase(fetchProcessLog.rejected, (state, action) => {
        state.processLogPageState.error = action.error.message;
        state.processLogPageState.loading = false;
      })

      .addCase(fetchPaginateProcessLog.pending, (state, action) => {
        state.processLogPageState.pagination.loading = true;
      })
      .addCase(fetchPaginateProcessLog.fulfilled, (state, action) => {
        state.processLogPageState.pagination.page = state.processLogPageState.pagination.page + 1;
        state.processLogPageState.data = state.processLogPageState.data.concat(action.payload.data);
        state.processLogPageState.pagination.hasMore = false;
        state.processLogPageState.pagination.loading = false;
      })
      .addCase(fetchPaginateProcessLog.rejected, (state, action) => {
        state.processLogPageState.error = action.error.message;
        state.processLogPageState.pagination.loading = false;
      })
      .addCase(resetProcessLogTable, (state, action) => {
        state.processLogPageState = {
          ...state.processLogPageState,
          data: [],
          pagination: {
            page: 2,
            pageSize: 30,
            hasMore: true,
            loading: false,
          },
        };
      })
      .addCase(updateRequestBodyForProcessLog, (state, action) => {
        state.processLogPageState.currentRequestBody = action.payload;
      })

      //Reset the Deatail Message in Brand Request Modal
      .addCase(clearDetailMessageofBrandRequestModal, (state, action) => {
        state.detailedMessages = "";
      })

      //Modal ProcessLog
      .addCase(processLogDetailsOpenModal, (state, action) => {
        state.processLogPageState.processLogModalState = true;
      })
      .addCase(processLogDetailsCloseModal, (state, action) => {
        state.processLogPageState.processLogModalState = false;
      })

      .addCase(getSelectedProcessLog, (state, action) => {
        const index = action.payload;
        state.processLogPageState.processLogDetail.data = state.processLogPageState.data[index];
      })
      .addCase(exportImageCollection.pending, (state, action) => {
        state.exportLoading = true;
      })
      .addCase(exportImageCollection.fulfilled, (state, action) => {
        state.exportLoading = false;
      })
      .addCase(exportImageCollection.rejected, (state, action) => {
        state.error = action.error.message;
        state.exportLoading = false;
      });
  },
});

export default ImageCollectionSlice.reducer;
