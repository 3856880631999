const region = "us-east-1";
const origin = window.location.origin;

const prod = {
  appSettingsData: {
    // generate a file format limit of video file
    federatedEngageAIProvider: "wl-dev-lc-production-ident-prov",
  },

  amplify_config: {
    Auth: {
      mandatorySignIn: true,
      region: region,
      userPoolId: "us-east-1_U37jZ8MqM",
      userPoolWebClientId: "1t05n0k9th2gvu8tc0mpl4mglp",
    },
    oauth: {
      domain: "wl-dev-logocloud-production.auth.us-east-1.amazoncognito.com",
      scope: [],
      redirectSignIn: `${origin}/live`,
      redirectSignOut: `${origin}/live`,
      responseType: "code",
      clientId: "1t05n0k9th2gvu8tc0mpl4mglp",
    },
    API: {
      endpoints: [
        {
          name: "logoCloud",
          // endpoint: "https://api.dev.wl-dms.com",
          endpoint: "https://api.production.wl-logocloud.com",
          region: region,
        },
        {
          name: "logoCloudLivePlatform",
          endpoint: "https://api.production.wl-logocloud.com/live",
          region: region,
        },
        {
          name: "logoCloudImageVerification",
          endpoint: "https://api.production.wl-logocloud.com/image-verifications",
          region: region,
        },
        {
          name: "logoCloudImageCollection",
          endpoint: "https://api.production.wl-logocloud.com/image-collections",
          region: region,
        },
        {
          name: "LogoCloudTenants",
          endpoint: "https://api.production.wl-logocloud.com/settings/tenants",
          region: region,
        },
      ],
    },
  },
};
// type demoType = typeof demo

export type ProdType = typeof prod;
export default prod;
