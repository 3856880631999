import {
  Form,
  Input,
  Checkbox,
  Button,
  Modal,
  UploadProps,
  UploadFile,
  Popover,
  Select,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "../../redux/useAppDispatch";

import Icon, {
  InboxOutlined,
  InfoCircleOutlined,

} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import CheckBoxPopOver from "./CheckBoxPopOver";
import {
  clearDetailMessageofBrandRequestModal,
  fetchTenantPickList,
  openBrandRequestSuccessModal,
  requestBrand,
} from "../../redux/ImageCollection/ImageCollectionAction";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { BrandRequestvalidateNameLength } from "./BrandRequestModalHelpers";

type BrandRequestModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
};

function BrandRequestModal(props: BrandRequestModalProps) {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isManualUpload, setIsManualUpload] = useState(false);
  const brandRequestLoading = useSelector(
    (state: RootState) => state.imageCollectionState.requestBrandLoading
  );
  const tenantPickList = useSelector(
    (state: RootState) => state.imageCollectionState.tenantPickList
  );
  const detailMessage = useSelector(
    (state: RootState) => state.imageCollectionState.detailedMessages
  );

  const isValidFormat = (type: string) => {
    return isManualUpload
      ? type === "application/x-zip-compressed"
      : type === "text/csv";
  };
  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    // disabled: true,
    showUploadList: false,

    beforeUpload: (file) => {
      if (!isValidFormat(file.type)) {
        return false;
      }
      setFileList((prev) => [file]);

      return false;
    },
  };
  const resetForm = () => {
    form.resetFields();
    setFileList([]);
    setIsManualUpload(false);
    dispatch(clearDetailMessageofBrandRequestModal());
  };
  useEffect(() => {
    dispatch(fetchTenantPickList({}));
  }, [props.openState]);
  const BrandRequestModalContent = useMemo(() => {
    const onFinish = async (values: any) => {
      try {
        const uploadRequestBody = {
          batchName: values.BatchName,
          jiraTicket: values.JiraTicket,
          tenantId: values.TenantName,
          isManualUpload: isManualUpload,
          inputFileName: fileList[0].name,
        };
        await dispatch(
          requestBrand({
            uploadRequestBody: uploadRequestBody,
            file: fileList[0],
          })
        ).unwrap();
        props.close();
        resetForm();
        dispatch(openBrandRequestSuccessModal());
      } catch (e) {}
    };

    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100">
        <h4>Brand Request</h4>

        <div className="d-flex flex-column gap-2">
          <Form
            form={form}
            name="createBrandForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            initialValues={{ isDummyDomain: false }}
            colon={false}
          >
            {/* Name Field */}
            <Form.Item
              className="form-item"
              label="Batch Name"
              name="BatchName"
              labelAlign="left"
              rules={[
                {
                  required: true,
                  message: "Please provide Batch Name.",
                },
                {
                  validator: BrandRequestvalidateNameLength,
                },
              ]}
            >
              <Input />
            </Form.Item>

            {/* Jira Field */}
         
            <Form.Item
              className="form-item"
              label="Jira Ticket ID"
              name="JiraTicket"
              labelAlign="left"
              required={true}
              rules={[
                {
                  required: true,
                  message: "Please provide Jira Ticket ID.",
                },
                {
                  pattern: /^[A-Za-z0-9]+-\d+$/,
                  message: "Invalid format! ",
                },
                {
                  validator: (_, value) => {
                    if (value && value.startsWith(" ")) {
                      return Promise.reject(
                        "Jira Ticket ID should not start with a space."
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              validateTrigger="onBlur"
            >
              <Input />
            </Form.Item>

            {/* Teanant */}

            <Form.Item
              className="form-item"
              label="Tenant Name"
              name="TenantName"
              labelAlign="left"
              required={true}
              //rule number only
              rules={[
                {
                  required: true,
                  pattern: /^[0-9]*$/,
                  message: "Please provide a valid Tenant Name.",
                },
              ]}
              validateTrigger="onBlur"
            >
              <Select
                showSearch
                placeholder="Select a Tenant"
                optionFilterProp="children"
              >
                {tenantPickList.map((tenant) => (
                  <Select.Option key={tenant.value} value={tenant.value}>
                    {tenant.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>



            {/* Upload */}
            <Form.Item
              className="form-item"
              label="Upload File"
              getValueFromEvent={(e) => {
                return e.fileList[0];
              }}
              name="InputFileName"
              labelAlign="left"
              required={true}
              rules={[
                {
                  validator: (_, value) => {
                    if (!fileList.length) {
                      return Promise.reject("Please upload a document.");
                    }
              
                    const file = fileList[0]; 
              
                    if (!file?.type) {
                      return Promise.reject("Invalid file type.");
                    }
              
                    if (!isValidFormat(file.type)) {
                      return Promise.reject(
                        `Please upload a ${isManualUpload ? "zip" : "csv"} format`
                      );
                    }
              
                    if (file?.size !== undefined && file.size <= 2) {
                      return Promise.reject("Uploading files should not be empty.");
                    }
              
                    return Promise.resolve();
                  },
                },
              ]}
              
            >
              <Dragger
                height={200}
                accept={isManualUpload ? ".zip" : ".csv"}
                {...uploadProps}
              >
                {fileList.length > 0 ? (
                  <div className="d-flex flex-column align-items-center" style={{ wordBreak: "break-all"}}>
                    {/* <Icon
                        {...getFileTypeIconProps({
                          extension: getExtension(fileList[0].type),
                          size: 96,
                          imageFileType: "svg",
                        })}
                      /> */}
                    {fileList[0].name}
                  </div>
                ) : (
                  <div>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag a file to this area to upload
                    </p>
                    <p className="ant-upload-hint">
                      Support for a single upload
                    </p>
                  </div>
                )}
              </Dragger>
            </Form.Item>


            {/*Manual Upload Checkbox */}
            <Form.Item
              name="IsManualUpload"
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 15 }}
              style={{ marginTop: "-20px" }}
            >
              <div className="manualUploadCheckBox">
                <Checkbox
                  onChange={(e) => {
                    form.resetFields(["InputFileName"]);
                    setFileList([]);

                    setIsManualUpload(e.target.checked);
                  }}
                >
                  <span className="manualUploadLabel">Manual Upload</span>
                </Checkbox>

                <Popover content={<CheckBoxPopOver />}>
                  <InfoCircleOutlined />
                </Popover>
              </div>
            </Form.Item>
            <div style={{ marginBottom: "15px" }}>
              <span style={{ color: "red", fontWeight: "bold" }}>
                {detailMessage}
              </span>
            </div>

            <div className="d-flex gap-3 justify-content-end">
              <Button
                className="saveButton"
                type="primary"
                loading={brandRequestLoading}
                htmlType="submit"
              >
                Create
              </Button>

              <Button
                htmlType="reset"
                loading={brandRequestLoading}
                onClick={() => {
                  props.close();
                  resetForm();
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  
  }, [form, props, isManualUpload, fileList]);

  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => (resetForm(), props.close())}
      open={props.openState}
    >
      {BrandRequestModalContent}
    </Modal>
  );
}

export default BrandRequestModal;
