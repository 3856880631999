import { Button, Form, Modal, Select } from "antd";
import Input from "antd/es/input/Input";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import { addStockSymbolValidator } from "../../../utils/addStockSymbolValidator";
import { useEffect } from "react";
import { editSymbolForImageVerification, setIsEditedForImageVerification, closeEditStockSymbolModalForImageVerification } from "../../../redux/ImageVerification/ImageVerificationAction";
import { validateISIN } from "../BrandQualityCheck/BrandQualityCheckHelpers";

type EditStockSymbolModalProps = {
  close: () => void;
  openState: boolean;
  open: () => void;
  // currentStockSymbolIndex: number;
};


function EditStockSymbolModal(props: EditStockSymbolModalProps) {
  const dispatch = useAppDispatch();
  const stockSymbolToUpdate = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.currentStockSymbol);
  const [form] = Form.useForm();
  const stockDropdownData = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.dropDownData.stockExchange);
  const stockSymbols = useSelector((state: RootState) => state.imageVerificationState.brandDetailState.data.stockSymbols);
  const EditStockSymbolContent = () => {
    const onFinish = (values: any) => {
      dispatch(editSymbolForImageVerification({ ...values }));
      dispatch(setIsEditedForImageVerification(true));
      form.resetFields();
      props.close();
    };

    useEffect(() => {
      form.setFieldsValue(stockSymbolToUpdate);
    }, []);
    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100 needs-validation">
        <h4>Edit Stock Symbol</h4>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex flex-column gap-2">
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
              form={form}
              autoComplete="off"
              initialValues={{
                symbol: stockSymbolToUpdate.symbol,
                exchangeName: stockSymbolToUpdate.exchangeName,
              }}
              colon={false}>
              <Form.Item
                label="Exchange"
                name="exchangeName"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide exchange.",
                  },
                ]}>
                <Select
                  placeholder="Please select exchange"
                  // defaultValue={stockSymbolToUpdate.exchangeName}
                  onChange={() => {
                    if (form.getFieldValue("symbol")) form.validateFields(["symbol"]);
                  }}>
                  {stockDropdownData.map((option) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>


              <Form.Item
                label={<span style={{ marginLeft: "10px" }}>ISIN</span>}
                name="isin"
                labelAlign="left"
                rules={[
                  {
                    
                    message: "Please provide ISIN.",
                  },
                  {
                    validator: validateISIN,
                  },
                ]}>
                <Input
                  placeholder="ISIN"
                  onBlur={() => form.validateFields(["exchangeName"])}
                />
              </Form.Item>


              <Form.Item
                label="Symbol"
                name="symbol"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide symbol.",
                  },
                  {
                    validator(rule, value, callback) {
                      if (value && !value.trim()) {
                        callback("Symbol cannot be empty.");
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Input placeholder="Symbol"/>
              </Form.Item>

              <div className="d-flex gap-3 justify-content-end">
                <Button
                  className="saveButton"
                  type="primary"
                  htmlType="submit">
                  Save
                </Button>

                <Button
                  htmlType="reset"
                  onClick={() => {
                    props.close();
                  }}
                  // form="createNewFolderForm"
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <EditStockSymbolContent />
    </Modal>
  );
}

export default EditStockSymbolModal;
