import { Modal } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { CheckCircleTwoTone } from "@ant-design/icons";

function BrandRequestSuccessModal(props: any) {
  const loading = useSelector((state: RootState) => state.imageCollectionState.requestBrandLoading);
  const BrandRequestSuccessContent = () => {
    return (
      <div className="d-flex flex-column justify-content-between gap-4 h-100">
        <h4>Brand Request</h4>

        <div className="d-flex flex-column gap-3 align-items-center mt-3">
          <CheckCircleTwoTone
            twoToneColor="#52c41a"
            style={{ fontSize: "50px" }}
          />
          <span>Brand Requested Successfully</span>
        </div>
      </div>
    );
  };
  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      onCancel={() => props.close()}
      open={props.openState}>
      <BrandRequestSuccessContent />
    </Modal>
  );
}

export default BrandRequestSuccessModal;
