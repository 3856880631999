import Header from "../Template/Header";
import { ColumnsType } from "antd/es/table";
import OpenProcessLogDetails from "./OpenProcessLogDetails";
const ProcessLogTableColumns: ColumnsType<any> = [
  {
    title: <Header data="Stage" />,
    dataIndex: "stage",
    sorter: true,
    width: 100,
    render: (data) => <span style={{ minWidth: "10vw", display: "inline-block" }}>{data}</span>,
  },
  {
    title: <Header data={"Processed At"} />,
    dataIndex: "createdAt",
    sorter: true,
    width: 100,
    render: (data) => <span style={{ minWidth: "8vw", display: "inline-block" }}>{data}</span>,
  },
  {
    title: <Header data={"Message"} />,
    dataIndex: "message",
    sorter: true,
    width: 150,
    render: (data) => <span style={{ minWidth: "20vw", display: "inline-block" }}>{data}</span>,
  },
  {
    title: <Header data={"Description"} />,
    dataIndex: "description",
    sorter: true,
    width: 200,
    render: (data, record, index) => (
        <OpenProcessLogDetails data={data} record={record} index={index} />
     
    ),
  },
];

export default ProcessLogTableColumns;
