import { Modal } from "antd";
import React, { useState, useRef, useEffect } from "react";
import ProcessLogDetailsModal from "./ProcessLogDetailsModal";
import { RootState } from "../../redux/Store";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/useAppDispatch";
import {
  getSelectedProcessLog,
  processLogDetailsCloseModal,
  processLogDetailsOpenModal,
} from "../../redux/ImageCollection/ImageCollectionAction";

interface OpenProcessLogDetailsProps {
  data: any;
  record: any;
  index: any;
}

const OpenProcessLogDetails = (props: OpenProcessLogDetailsProps) => {
  const processLogModalState = useSelector(
    (state: RootState) =>
      state.imageCollectionState.processLogPageState.processLogModalState
  );

  const dispatch = useAppDispatch();

  const [showFullText, setShowFullText] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  const { data } = props;
  const fullData = data.split("|||");
  const firstPart = fullData[0];

  // Check if the text is overflowing
  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth
      );
    }
  }, []);

  // Handle clicking "More"
  const handleMoreClick = () => {
    if (fullData.length > 1) {
      dispatch(processLogDetailsOpenModal());
      dispatch(getSelectedProcessLog(props.index));
    } else {
      setShowFullText(true);
    }
  };

  return (
    <>
      <span
        ref={textRef}
        style={{
          whiteSpace: showFullText ? "normal" : "nowrap",
          overflow: showFullText ? "visible" : "hidden",
          textOverflow: showFullText ? "clip" : "ellipsis",
          maxWidth: "1000px",
          display: "inline-block",
        }}
      >
        {showFullText ? data : firstPart}
      </span>
      {(fullData.length > 1 || isOverflowing) && !showFullText && (
        <div className="link" onClick={handleMoreClick}>
          More
        </div>
      )}
      <ProcessLogDetailsModal
        openState={processLogModalState}
        open={() => dispatch(processLogDetailsOpenModal())}
        close={() => dispatch(processLogDetailsCloseModal())}
      />
    </>
  );
};

export default OpenProcessLogDetails;
