import React from "react";
import "./Footer.css";

function Footer(props: any) {
  return (
    <div className="footer-section d-flex gap-2 gap-lg-4  py-2  flex-lg-row flex-column justify-content-lg-between align-items-center">
      <a
        className=" "
        href="https://www.williamslea.com"
        target="_blank"
        rel="noreferrer">
        <img
          src={require("../../../assets/img/wl_white_logo.png")}
          alt=""
        />
      </a>
      <div className="d-flex gap-4   ">
        <a
          target="_blank"
          href="https://www.williamslea.com/about"
          className="footer-link"
          rel="noreferrer">
          About
        </a>
        <a
          target="_blank"
          href="https://www.williamslea.com/privacy-statement"
          className="footer-link"
          rel="noreferrer">
          Privacy
        </a>
        <a
          target="blank"
          href="https://www.williamslea.com/cookies"
          className="footer-link">
          Cookies
        </a>
      </div>
      <p className="  text-center  ">Copyright © 2025 Williams Lea. All Rights Reserved. </p>
    </div>
  );
}

export default Footer;
