import React from "react";
import { useNavigate } from "react-router";
function ProcessLogNavigationLink(props: any) {
  const navigate = useNavigate();
  return (
    <div
      className="link"
      onClick={() => navigate(`process-log/${props.record.id}`, { state: { record: props.record } })}>
      {props.data}
    </div>
  );
}

export default ProcessLogNavigationLink;
