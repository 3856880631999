import { validationLimitValues } from "../../constant/constant";


export const BrandRequestvalidateNameLength = (rule: any, value: any, callback: any) => {
  const inValidcharAt = /^[^\/\\:?,<>|*]+$/;
  const specialCharsOnly = /^[^a-zA-Z0-9]+$/;
  if (value && !value.trim()) {
    return Promise.reject("Batch name cannot be empty.");
  } else {
    if (value && value.length > validationLimitValues.brandNameMaxLength) {
      callback(`Please provide batch name less than ${validationLimitValues.brandNameMaxLength} characters.`);
    }else if (value && !inValidcharAt.test(value)) {
      return Promise.reject(`Batch name cannot contain the following characters \\ / : ? , < > | *`);
    } else if (value && specialCharsOnly.test(value)) {
      return Promise.reject("Please provide a valid batch name.");
    } else {
      callback();
    }
  }
};