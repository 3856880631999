import { ColumnsType } from "antd/es/table";

// import { devToolsEnhancer } from "@reduxjs/toolkit/dist/devtoolsExtension";
import IsDataNull from "../Template/IsDataNull";
// import BrandDetailNavigationLink from "./BrandDetailNavigationLink";
import ShortenedName from "../Template/ShortenedName";
import Header from "../Template/Header";
import ProcessLogNavigationLink from "./ProcessLogNavigationLink";

const ImageCollectionTableColumns: ColumnsType<any> = [
  {
    title: <Header data="Name" />,
    dataIndex: "name",
    width: 200,
    sorter: true,
    render: (data, record) => (
      <ProcessLogNavigationLink
        data={data}
        record={record}
      />
    ),
  },
  {
    title: <Header data={"Website"} />,
    width: 150,
    dataIndex: "website",
    sorter: true,
    render: (data) =>
      data ? (
        <ShortenedName
          data={data}
          maxLength={30}
          toolTipText={data}
        />
      ) : (
        "-"
      ),
  },

  {
    title: <Header data={"Images Count"} />,
    dataIndex: "imagesCount",
    key: "shortMessage",
    
    sorter: true,
    width: 100,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: <Header data={"Started At"} />,
    dataIndex: "startedAt",
    sorter: true,
    width: 200,
    render: (data) => <IsDataNull data={data} />,
  },
  {
    title: <Header data={"Completed At"} />,
    dataIndex: "finishedAt",
    sorter: true,
    width: 200,
    render: (data) => <IsDataNull data={data} />,
  },

  {
    title: <Header data={"Status"} />,
    dataIndex: "status",
    sorter: true,
    width: 200,
    render: (data) => <IsDataNull data={data} />,
  },
];

export default ImageCollectionTableColumns;
