import { Tooltip } from "antd";

type ShortenedNameInputType = {
  data: string;
  maxLength: number;
  toolTipText: string;
};

const ShortenedName = (props: ShortenedNameInputType) => {
  let shortenedData = props.data;

  if (!props.data) {
    shortenedData = "-";
  }

  if (props.data.length > props.maxLength) {
    shortenedData = props.data.substring(0, props.maxLength) + "...";
  }

  return (
    <div style={{ whiteSpace: "nowrap", width: "fit-content" }}>
      <Tooltip
        placement="top"
        title={props.toolTipText}>
        {shortenedData}
      </Tooltip>
     
    </div>
  );
};

export default ShortenedName;
