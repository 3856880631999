import { Button, Checkbox, Form, Input, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/Store";
import { useAppDispatch } from "../../../redux/useAppDispatch";
import ImageViewer from "../../ImageViewer/ImageViewer";
import AddStockSymbolModal from "../ModalComponent/AddStockSymbolModal";
import DeleteStockSymbolModal from "../ModalComponent/DeleteStockSymbolModal";
import EditStockSymbolModal from "../ModalComponent/EditStockSymbolModal";
import StockSymbolTable from "../TableComponent/StockSymbolTable";
import { StockSymbolTableColumns } from "../TableComponent/StockSymbolTableColumns";
import { validateDomain, validateISIN, validateNameLength } from "./BrandQualityCheckHelpers";
import "./BrandQualityCheckTab.css";
import { saveBrandDetail, setIsEdited, openAddStockSymbolModal, closeAddStockSymbolModal, openEditStockSymbolModal, closeEditStockSymbolModal, openDeleteStockSymbolModal, closeDeleteStockSymbolModal, setStockSymbols, setCurrentImageIndex } from "../../../redux/LivePlatform/LivePlatformAction";
import UploadImageComponent from "../UploadImageComponent/UploadImageComponent";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    lg: { span: 24 },
    xl: { span: 19 },
  },
};

function BrandQualityCheck(props: any) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [uploadOpenState, setUploadOpenState] = useState(false);
  const brandDetailState = useSelector((state: RootState) => state.livePlatformState.brandDetailState);
  const isEditing = brandDetailState.isEdited;
  const [visible, setVisible] = useState(false);
  const [descriptionCharCount, setDescriptionCharCount] = useState(4000 - brandDetailState.data.description.length);
  const [isDummyDomain, setIsDummyDomain] = useState(brandDetailState.data.isDummyDomain);
  const pageType = "Live";

  useEffect(() => {
    form.setFieldsValue(brandDetailState.data);
    form.setFieldValue("country", brandDetailState?.data?.brandLocation?.country);
    const previousStockSymbols = brandDetailState.data.stockSymbols.filter((stockSymbol: any) => stockSymbol.id !== undefined);
    dispatch(setStockSymbols(previousStockSymbols));
  }, [props.tabKey]);

  useEffect(() => {
    form.setFieldValue("stockSymbols", brandDetailState.data.stockSymbols);
  }, [brandDetailState.data.stockSymbols]);
  useEffect(() => {
    if (brandDetailState.data?.domain) {
      // Update the form field when domain changes
      form.setFieldValue("domain", brandDetailState.data.domain);
    }
  }, [brandDetailState.data?.domain]);
  useEffect(() => {
    setVisible(true);

    return () => {
      setVisible(false);
    };
  }, []);

  function openUpload() {
    setUploadOpenState(true);
  }
  function closeUpload() {
    setUploadOpenState(false);
  }

  const onFinish = (values: any) => {
    const brandLocation = {
      id: brandDetailState?.data?.brandLocation?.id,
      country: values.country,
      brandId: props.brandId,
    };

    dispatch(
      saveBrandDetail({
        brandDetail: { ...values, brandLocation: brandLocation },
        brandId: props.brandId,
      })
    );
    dispatch(setIsEdited(false));
  };

  const handleClear = () => {
    // Set form fields to the brandDetailState data
    form.setFieldsValue(brandDetailState.data);
    form.validateFields(["domain"]);
    // Set the country field from brandLocation
    form.setFieldValue("country", brandDetailState.data?.brandLocation?.country);
    form.setFieldValue("domain", brandDetailState.data?.domain);

    // Filter stockSymbols where id is defined
    const previousStockSymbols = brandDetailState.data.stockSymbols.filter((stockSymbol: any) => stockSymbol.id !== undefined);

    // Dispatch stock symbols to the state
    dispatch(setStockSymbols(previousStockSymbols));
    setIsDummyDomain(brandDetailState.data.isDummyDomain);
    // Update character count based on description length
    setDescriptionCharCount(4000 - brandDetailState.data.description.length);
  };

  return (
    <div
      className="d-flex h-100 flex-column flex-md-row"
      key={2}>
      <AddStockSymbolModal
        open={() => dispatch(openAddStockSymbolModal())}
        close={() => dispatch(closeAddStockSymbolModal())}
        openState={brandDetailState.openStockSymbolModalState}
      />
      <EditStockSymbolModal
        open={() => dispatch(openEditStockSymbolModal())}
        close={() => dispatch(closeEditStockSymbolModal())}
        openState={brandDetailState.openEditStockSymbolModalState}
      />
      <DeleteStockSymbolModal
        open={() => dispatch(openDeleteStockSymbolModal())}
        close={() => dispatch(closeDeleteStockSymbolModal())}
        openState={brandDetailState.openDeleteStockSymbolModalState}
      />
      <UploadImageComponent
        brandName={props.brandName}
        brandId={props.brandId}
        batchId={props.batchId}
        open={openUpload}
        close={closeUpload}
        openState={uploadOpenState}
      />
      <div className="form-container d-flex  justify-content-between   flex-column pe-0 pe-md-4  py-3 ">
        <div className="d-flex flex-column full-flex h-100 overflow-auto">
          <Spin
            spinning={brandDetailState.saveLoading}
            className="overflow-hidden">
            <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              // disabled={props.isLocked}
              disabled={brandDetailState.data.isArchived === true || props.isLocked}
              initialValues={{ isDummyDomain: isDummyDomain }}
              colon={false}
              // style={{ maxWidth: 600 }}
              scrollToFirstError
              onValuesChange={() => {
                dispatch(setIsEdited(true));
              }}>
              <Form.Item
                className="form-item"
                name="name"
                label="Name"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: "Please provide brand name.",
                  },
                  {
                    validator: validateNameLength,
                  },
                ]}>
                <Input />
              </Form.Item>

              <Form.Item
                className="form-item"
                name="domain"
                label="Website"
                labelAlign="left"
                // required={!isDummyDomain}
                required={true}
                rules={[
                  // {
                  //   required: true,
                  //   message: "Please provide website.",
                  // },
                  {
                    validator: (rule, value, callback) => validateDomain(rule, value, isDummyDomain, callback),
                  },
                ]}>
                <Input disabled={isDummyDomain} />
              </Form.Item>
              <Form.Item
                name="isDummyDomain"
                valuePropName="checked"
                wrapperCol={{ offset: 5, span: 10 }}
                style={{ marginTop: "-20px", marginBottom: "0px" }}>
                <Checkbox
                  onChange={(e) => {
                    const checked = e.target.checked;
                    setIsDummyDomain(checked);
                    form.setFieldValue("isDummyDomain", checked);

                    form.validateFields(["domain"]);
                  }}>
                  Is dummy website?
                </Checkbox>
              </Form.Item>
              <Form.Item
                className="form-item"
                name="country"
                labelAlign="left"
                label={<span className="nonMandatoryInputFields">Country</span>}>
                <Select
                  placeholder="Please select country."
                  showSearch>
                  {brandDetailState.dropDownData.country.map((option, idx) => (
                    <Select.Option
                      key={option.value}
                      value={option.value}>
                      <div>{option.label}</div>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="tags"
                label={<span className="nonMandatoryInputFields">Tags</span>}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value && value.length > 0) {
                        const lastTag = value[value.length - 1].trim();
                        if (lastTag === "") {
                          callback("Please provide valid tags (Tags cannot end with empty spaces)");
                        } else {
                          callback();
                        }
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
                labelAlign="left">
                <Select
                  mode="tags"
                  options={[]}
                  tokenSeparators={[",", ";"]}
                />
                {/* <Input onChange={(value) => form.setFieldValue("tags", value.target.value.split(","))} /> */}
              </Form.Item>

              <Form.Item
                className="descriptionField"
                name="description"
                labelAlign="left"
                label={<span className="nonMandatoryInputFields">Description</span>}>
                <TextArea
                  onChange={() => {
                    setDescriptionCharCount(4000 - form.getFieldValue("description").length);
                  }}
                  autoSize={{ minRows: 3, maxRows: 3 }}
                  maxLength={4000}
                />
              </Form.Item>
              <div className="brandPageCharCount">{descriptionCharCount} remaining characters.</div>

              <Form.Item
                className="form-item"
                name="stockSymbols"
                labelAlign="left"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label={<span className="nonMandatoryInputFields">Stock Symbols</span>}>
                <div
                  className="d-flex flex-column full-flex h-100 w-100"

                  // label="Stock Symbols"
                >
                  <StockSymbolTable
                    setIsEditing={props.setIsEditing}
                    dataSource={brandDetailState.data.stockSymbols}
                    columns={StockSymbolTableColumns}
                  />
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </div>

        <div className="d-flex w-100 justify-content-end">
          <div className="d-flex gap-4">
            <Button
              onClick={() => {
                form.submit();
              }}
              className="saveButton "
              disabled={isEditing ? false : true}
              // disabled={brandDetailState.saveLoading}
              type="primary">
              Save
            </Button>
            {/* <Button htmlType="reset">Cancel</Button> */}
            <Button
              htmlType="reset"
              // className="action-btn"
              disabled={brandDetailState.saveLoading}
              onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>
      </div>
      <div className="responsive-border d-flex flex-column full-flex h-100 pt-3 ps-0 ps-md-4 ">
        {props.images.length > 0 ? (
          <ImageViewer
            brandId={props.brandId}
            batchId={props.batchId}
            pageType={pageType}
            brandDetailState={brandDetailState}
            setCurrentImageIndex={(index: number) => dispatch(setCurrentImageIndex(index))}
            images={props.images}
            id_key={"0"}
            visible={true}
          />
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <p className="imageNotFoundMsg">No images available</p>
          </div>
        )}
        {/* <div className="link downloadImageLink">Download Image</div> */}
        <div className="d-flex align-items-center py-3 gap-2">
          {/* <Switch />
              <span>Select All</span> */}
          {/* <div className="link downloadImageLink">Download Image</div> */}
          <Button
            className="saveButton"
            onClick={() => openUpload()}
            disabled={props.isLocked || brandDetailState.data.isArchived === true}
            type="primary">
            Upload Image
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BrandQualityCheck;
