import React, { useEffect, useMemo } from "react";

import { Button, Form, Input, Modal, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";

export interface ProcessLogDetailsProps {
  close: () => void;
  openState: boolean;
  open: () => void;
}

function ProcessLogDetailsModal(props: ProcessLogDetailsProps) {
  const processLog=useSelector((state: RootState) => state.imageCollectionState.processLogPageState.processLogDetail.data);

  const [form] = Form.useForm();
  //Modal Close
  const handleModalClose = () => {
    props.close();
  };

  //Populating the from

  useEffect( () =>{
    if(processLog){

      form.setFieldsValue({
        Stage:processLog.stage,
        ProcessedAt:processLog.createdAt,
        Message:processLog.message,
        Description:processLog.description?.replaceAll("|||", "\n"),
      });
    }
  },[processLog,form])

  //ModalContent
  const ProcessLogDetailsModalContent = useMemo(() => {
    return (
    
        <div className="d-flex flex-column gap-4">
          <h4>Process Log Details</h4>

          <Form
            form={form}
            name="processlogForm"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 24 }}
            style={{ maxWidth: 600 }}
            labelAlign={"left"}
          >
            <Form.Item label="Stage" name="Stage" colon={false}>
              <Input readOnly={true} />
            </Form.Item>

            <Form.Item label="Processed At" name="ProcessedAt" colon={false}>
              <Input readOnly={true} />
            </Form.Item>
            <Form.Item label="Message" name="Message" colon={false}>
              <Input readOnly={true} />
            </Form.Item>
            <Form.Item label="Description" name="Description" colon={false}>
              <TextArea autoSize={{ minRows: 3, maxRows: 7 }} readOnly={true} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 21 }}>
              <Button
                type="primary"
                htmlType="button"
                onClick={handleModalClose}
              >
                Close
              </Button>
            </Form.Item>
          </Form>
        </div>
     
    );
  }, [form,]);

  return (
    <Modal
      centered
      zIndex={1500}
      footer={[]}
      width={600}
      onCancel={handleModalClose}
      open={props.openState}
      styles={{
       mask:{
        backgroundColor: "rgba(0, 0, 0, 0.05)" 
       },
        content: {
          boxShadow: "none", 
        },
      }}
    >
      {ProcessLogDetailsModalContent}
    </Modal>
  );
}

export default ProcessLogDetailsModal;
