// import csvFile from "../../assets/data/UploadTemplate.csv"
function CheckBoxPopOver() {
  function downloadFromUrl(url: any, filename: string) {
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  return (
    <div>
      Select this option to manually ingest images into the store.<br></br>
      <div
        className="link"
        onClick={() => downloadFromUrl("/UploadTemplate.csv", "UploadTemplate.csv")}>
        Click here
      </div>{" "}
      to download template.
    </div>
  );
}

export default CheckBoxPopOver;
