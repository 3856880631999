import React, { useEffect, useState } from "react";
import TableComponent from "../../components/TableComponent/TableComponent";
import Search from "antd/es/input/Search";
import LoadingPage from "../templates/LoadingPage/LoadingPage";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { RootState } from "../../redux/Store";
import { useSelector } from "react-redux";
import { Button, Select } from "antd";
import { resetImageCollectionTable, updateRequestBodyForImageCollection, fetchBrandsImageCollection, searchImageCollectionBrands, stopAutoRefreshForImageCollection, fetchPaginateBrandsImageCollection, openBrandRequestModal, closeBrandRequestModal, openBrandRequestSuccessModal, closeBrandRequestSuccessModal, exportImageCollection } from "../../redux/ImageCollection/ImageCollectionAction";
import AutoRefreshCheckboxForImageCollection from "../../components/ImageCollectionComponent/AutoRefreshCheckForImageCollection";
import ImageCollectionTableColumns from "../../components/ImageCollectionComponent/ImageCollectionTableColumns";
import BrandRequestModal from "../../components/ImageCollectionComponent/BrandRequestModal";
import RequestBrandLoadingModal from "../../components/ImageCollectionComponent/BrandRequestSuccessModal";
import BrandRequestSuccessModal from "../../components/ImageCollectionComponent/BrandRequestSuccessModal";

function ImageCollectionPage(props: any) {
  const imageCollectionPageState = useSelector((state: RootState) => state.imageCollectionState);
  const brandRequestModalOpenState = useSelector((state: RootState) => state.imageCollectionState.brandRequestModalOpenState);
  const brandRequestSuccessModalOpenState = useSelector((state: RootState) => state.imageCollectionState.brandRequestSuccessModalOpenState);
  const exportLoading = useSelector((state: RootState) => state.imageCollectionState.exportLoading);

  const [searchValue, setSearchValue] = useState(imageCollectionPageState.currentRequestBody.keyword);
  const [firstFetch, setFirstFetch] = useState(false);
  const dispatch = useAppDispatch();
  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = {
      ...imageCollectionPageState.currentRequestBody,
      sortColumn: sorter.field,
      isAsc: isAscend(sorter.order),
    };
    dispatch(resetImageCollectionTable());
    dispatch(updateRequestBodyForImageCollection(newSortingRequestBody));
    dispatch(fetchBrandsImageCollection({}));
  };
  const onSearch = (value: string) => {
    dispatch(
      searchImageCollectionBrands({
        searchKeyword: value,
      })
    );
  };

  useEffect(() => {
    // dispatch(updateRequestBodyForImageCollection({ ...imageCollectionPageState.currentRequestBody, batchId: imageCollectionPageState.currentBatchId }));
    const fetchBrands = async () => {
      await dispatch(fetchBrandsImageCollection({}));
      setFirstFetch(true);
    };
    fetchBrands();

    return () => {
      dispatch(stopAutoRefreshForImageCollection());
      dispatch(resetImageCollectionTable());
      setFirstFetch(false);
    };
  }, []);
  if (imageCollectionPageState.loading && !firstFetch) {
    return <LoadingPage />;
  }
  return (
    <div className="body-container gap-3">
      <div className="d-flex justify-content-between">
        <h2>Brand Request</h2>
        <div>
          {/* <AutoRefreshCheckboxForImageCollection /> */}
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="search-bar">
          <Search
            type="text"
            size="large"
            value={searchValue}
            onSearch={(value) => onSearch(value)}
            allowClear
            placeholder="Search by Name or Website "
            onChange={(e) => setSearchValue(e.target.value)}
            style={{ width: 580 }}
            //style to make the content in the center horizontally of the parent position relative
          />
        </div>

        {/* <AutoRefreshCheckbox /> */}
        <div className="d-flex gap-3 align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <span>Batch</span>
            <Select
              style={{ width: 400 }}
              loading={imageCollectionPageState.loading}
              onSelect={(value) => {
                dispatch(resetImageCollectionTable());
                dispatch(updateRequestBodyForImageCollection({ ...imageCollectionPageState.currentRequestBody, batchId: value }));
                dispatch(fetchBrandsImageCollection({}));
                // dispatch(resetImageCollectionTable());
              }}
              showSearch
              defaultValue={imageCollectionPageState.currentBatchId}
              options={imageCollectionPageState.batchPickList}
              filterOption={(input, option) => {
                const label = option?.label as string;
                const lowerCaseLabel = label.toLowerCase();
                return lowerCaseLabel.includes(input.toLowerCase());
              }}
            />
          </div>
          <Button
            style={{ fontSize: "15px", boxShadow: "none" }}
            type="primary"
            loading={exportLoading}
            onClick={() => dispatch(exportImageCollection({ batchId: imageCollectionPageState.currentBatchId }))}>
            Export
          </Button>
          <Button
            style={{ fontSize: "15px", boxShadow: "none" }}
            type="primary"
            onClick={() => {
              dispatch(openBrandRequestModal());
            }}>
            Brand Request
          </Button>
        </div>
      </div>

      <TableComponent
        columns={ImageCollectionTableColumns}
        dataSource={imageCollectionPageState.data}
        pagination={imageCollectionPageState.pagination}
        handleTableSorting={handleTableSorting}
        loading={imageCollectionPageState.loading}
        fetchPaginatedData={() => fetchPaginateBrandsImageCollection({})}
        tableKey="imageCollectionTableKey"
      />
      <BrandRequestModal
        openState={brandRequestModalOpenState}
        open={() => dispatch(openBrandRequestModal())}
        close={() => dispatch(closeBrandRequestModal())}
      />
      <BrandRequestSuccessModal
        openState={brandRequestSuccessModalOpenState}
        open={() => dispatch(openBrandRequestSuccessModal())}
        close={() => dispatch(closeBrandRequestSuccessModal())}
      />
    </div>
  );
}

export default ImageCollectionPage;
