import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { saveAs } from "file-saver";
import { del, get, post, put } from "../../services/API/ApiClient";
import { DefaultAuditRequestBodyType, DefaultRequestBodyType, ImageType } from "../../constant/logoProductionType";
import { fetchDropdownData } from "../LogoProduction/LogoProductionAction";
import { notification } from "antd";
import { openErrorNotification, openNotification, openStaticErrorNotification, openStaticErrorNotificationForCreateBrand } from "../../components/Template/NotificationComponent";

////////////////Start of live platform action
export const fetchLogos = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchLogos", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().livePlatformState.currentRequestBody,
    };

    const response = await post("/search", "logoCloudLivePlatform", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const fetchPaginateLogos = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchPaginateLogos", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().livePlatformState.currentRequestBody,
      pageNum: getState().livePlatformState.pagination.page,
    };
    const response = await post("/search", "logoCloudLivePlatform", reqBody);

    return {
      data: response.data.data,
      hasMore: response.data.data.length > 0,
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const searchDocuments = createAsyncThunk<any, { searchKeyword: string; includeArchiveBrands: boolean }, { state: RootState }>("logoProduction/searchDocuments", async (args, { getState, dispatch }) => {
  dispatch(resetTable());
  dispatch(updateRequestBody({ ...getState().livePlatformState.currentRequestBody, keyword: args.searchKeyword, includeArchiveBrands: args.includeArchiveBrands }));

  dispatch(fetchLogos({}));
});
export const fetchRefreshDocuments = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchRefreshLogos", async (args, { getState, dispatch, rejectWithValue }) => {
  const currentDataLength = getState().livePlatformState.data.length === 0 ? 30 : getState().livePlatformState.data.length;
  try {
    const reqBody = {
      ...getState().livePlatformState.currentRequestBody,
      pageSize: Math.ceil(currentDataLength / 30) * 30,
    };

    const response = await post("/search", "logoCloudLivePlatform", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const autoRefresh = createAsyncThunk<any, any, { state: RootState }>("logoProduction/autoRefresh", async (args, { getState, dispatch, rejectWithValue }) => {
  if (getState().livePlatformState.autoRefreshState.currentIntervalId) {
    clearInterval(getState().livePlatformState.autoRefreshState.currentIntervalId);
  }
  if (getState().livePlatformState.autoRefreshState.autoRefresh) {
    const intervalId = setInterval(() => {
      dispatch(fetchRefreshDocuments({}));
    }, 5000);
    dispatch(setAutoRefresh({ intervalId }));
  }
});

//Start the generic action for live platform
export const resetTable = createAction("logoProduction/resetTable");
export const updateRequestBody = createAction<DefaultRequestBodyType>("logoProduction/updateRequestBody");
export const stopAutoRefresh = createAction("logoProduction/stopAutoRefresh");
export const switchAutoFresh = createAction<{ autoRefresh: boolean }>("logoProduction/switchAutoFresh");
export const setAutoRefresh = createAction<{ intervalId: NodeJS.Timer }>("logoProduction/setAutoRefresh");
//End the generic action for live platform

//Start action of live platform detail page
export const fetchBrandDetail = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchBrandDetail", async (brandId, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await get(`/brands/${brandId}`, "logoCloudLivePlatform");

    const dropdownData = await fetchDropdownData();

    return { brandDetail: response, dropdownData: dropdownData };
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const downloadLiveImage = createAsyncThunk<any, { imageId: number; brandId: number }, { state: RootState }>("logoProduction/downloadLiveImage", async (args, { getState, dispatch, rejectWithValue }) => {
  // const response = await get(`/brands/${args.brandId}/images/${args.imageId}`, "logoCloudLivePlatform");

  try {
    const response = await get(`/brands/${args.brandId}/images/${args.imageId}`, "logoCloudLivePlatform");
    const imageUrl = response.data.downloadUrl;

    // Fetch the image data
    const imageResponse = await fetch(imageUrl);
    const imageBlob = await imageResponse.blob();

    // Extract the image format from the response (e.g., "png")
    const imageFormat = response.data.imageFormat;

    // Use the saveAs function to download the image
    saveAs(imageBlob, `${args.imageId}.${imageFormat}`);
  } catch (error: any) {
    return rejectWithValue(error.response?.data || "Error downloading image");
  }
});
export const saveBrandDetail = createAsyncThunk<any, { brandDetail: any; brandId: number }, { state: RootState }>("logoProduction/saveBrandDetail", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();

    const response = await post(`/brands`, "logoCloudLivePlatform", { ...args.brandDetail, id: args.brandId });
    openNotification("Brand Details Saved", `Brand details for '${args.brandDetail.name}' has been saved successfully,`, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to save brand details.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
//CreateBrand
export const createBrand = createAsyncThunk<any, { brandDetail: any }, { state: RootState }>("logoProduction/createBrand", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    const response = await put(`/brands`, "logoCloudLivePlatform", { ...args.brandDetail, Id: args.brandDetail.Id });
    openNotification("Brand Created Successfully", response.message, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotificationForCreateBrand("Failed to create brand.", e.response?.data?.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
//Delete Brand
export const DeleteBrand = createAsyncThunk<any, { brandId: number; brandName: string }, { state: RootState }>("logoProduction/deleteBrand", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    const response = await del(`/brands/${args.brandId}`, "logoCloudLivePlatform");
    openNotification("Brand Deleted", response.message, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to delete brand.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
//Archive Brand
export const ArchiveBrand = createAsyncThunk<any, { brandId: number; brandName: string }, { state: RootState }>("logoProduction/archiveBrand", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    const response = await post(`/brands/${args.brandId}/archive`, "logoCloudLivePlatform", {});
    openNotification("Brand Archived", response.message, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to archive brand.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
//UnArchive Brand
export const UnArchiveBrand = createAsyncThunk<any, { brandId: number; brandName: string }, { state: RootState }>("logoProduction/UnArchiveBrand", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    const response = await post(`/brands/${args.brandId}/unarchive`, "logoCloudLivePlatform", {});
    openNotification("Brand UnArchived", response.message, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to Unarchive brand.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
export const saveIndustryDetail = createAsyncThunk<any, { industryDetail: any; brandId: number; brandName: string }, { state: RootState }>("logoProduction/saveIndustryDetail", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    const response = await post(`/brands/${args.brandId}/industries`, "logoCloudLivePlatform", { ...args.industryDetail, brandId: args.brandId });
    openNotification("Industry Details Saved", `Industry details for '${args.brandName}' has been saved successfully,`, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to save industry details.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
//upload image for live platform
export const uploadImage = createAsyncThunk<any, { brandName: string; image: any; brandId: string }, { state: RootState }>("logoProduction/uploadImage", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();

    const formData = new FormData();
    formData.append("imageFile", args.image);
    const response = await post(`/brands/${args.brandId}/images/upload`, "logoCloudLivePlatform", formData);

    openNotification("Image Upload Successful", `Image uploaded successfully for brand '${args.brandName}'.`, "top");
    return response.data;
  } catch (e: any) {
    openStaticErrorNotification("Failed to upload image.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
export const saveImageDetail = createAsyncThunk<any, { imageDetail: ImageType; imageId: number; brandId: number }, { state: RootState }>("logoProduction/saveImageDetail", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await post(`/brands/${args.brandId}/images/${args.imageId}`, "logoCloudLivePlatform", { ...args.imageDetail, id: args.imageId, brandId: args.brandId });
    openNotification("Image Details Saved", `Image ID -${args.imageId}, details has been saved successfully.`, "top");
    return response.data;
  } catch (e: any) {
    openErrorNotification("Failed to save image details.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});
export const deleteImage = createAsyncThunk<any, { imageId: number; brandId: number }, { state: RootState }>("logoProduction/deleteImage", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await del(`/brands/${args.brandId}/images/${args.imageId}`, "logoCloudLivePlatform");
    openNotification("Image Deleted", `Image (ID: ${args.imageId}) has been deleted successfully`, "top");

    dispatch(setIsEdited(false));
    return response.data;
  } catch (e) {
    openErrorNotification("Failed to delete image.", `Image (ID: ${args.imageId}) has not deleted.Please try again later.`, "top");
    return rejectWithValue(e);
  }
});
export const acceptImage = createAsyncThunk<any, { imageId: number; imageDetail: ImageType; brandId: number }, { state: RootState }>("logoProduction/acceptImage", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await post(`/brands/${args.brandId}/images/${args.imageId}/accept`, "logoCloudLivePlatform", { ...args.imageDetail, id: args.imageId, brandId: args.brandId });
    openNotification("Image Accepted", `Image (ID: ${args.imageId}) has been accepted successfully`, "top");
    return response.data;
  } catch (e: any) {
    openErrorNotification("Failed to accept image.", `Image (ID: ${args.imageId}) has not accepted.Please try again later.`, "top");
    return rejectWithValue(e);
  }
});
export const fetchAuditEntriesDetails = createAsyncThunk<any, any, { state: RootState }>("logoProduction/audit-entries", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().livePlatformState.auditEntriesState.currentRequestBody,
    };
    const response = await post("/brands/audit-entries", "logoCloudLivePlatform", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const fetchAuditPaginateEntries = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchAuditPaginateEntries", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().livePlatformState.auditEntriesState.currentRequestBody,
      pageNum: getState().livePlatformState.auditEntriesState.pagination.page,
    };
    const response = await post("/brands/audit-entries", "logoCloudLivePlatform", reqBody);

    return {
      data: response.data,
      hasMore: response.data.length > 0,
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const updateStockSymbol = createAction<{ symbol: string; exchangeName: string; isin: string; id: number; index: number; }>("updateStockSymbolSlice/updateStockSymbol");
export const setStockSymbols = createAction<any>("logoProduction/setStockSymbols");
export const addSymbol = createAction<{ symbol: string; exchangeName: string ; isin: string;}>("logoProduction/addSymbol");
export const editSymbol = createAction<{ symbol: string; exchangeName: string; isin: string; }>("logoProduction/editSymbol");
export const deleteSymbol = createAction<{ symbol: string; exchangeName: string; isin: string; }>("logoProduction/deleteSymbol");
export const setCurrentImageIndex = createAction<number>("logoProduction/setCurrentImageIndex");
export const cleanBrandDetail = createAction("logoProduction/cleanBrandDetail");
export const setIsEdited = createAction<boolean>("logoProduction/setIsUpdated");
export const updateAuditRequestBody = createAction<DefaultAuditRequestBodyType>("logoProduction/updateAuditRequestBody");
export const resetAuditEntryTable = createAction("logoProduction/resetAuditEntryTable");

//End action of live platform detail page

//Start Action for live platform modal
export const openAddStockSymbolModal = createAction("logoProduction/openAddStockSymbolModal");
export const openEditStockSymbolModal = createAction("logoProduction/openEditStockSymbolModal");
export const openDeleteStockSymbolModal = createAction("logoProduction/openDeleteStockSymbolModal");
export const closeDeleteStockSymbolModal = createAction("logoProduction/closeDeleteStockSymbolModal");
export const closeEditStockSymbolModal = createAction("logoProduction/closeEditStockSymbolModal");
export const closeAddStockSymbolModal = createAction("logoProduction/closeAddStockSymbolModal");
export const openConfirmDeleteModal = createAction("logoProduction/openConfirmDeleteModal");
export const closeConfirmDeleteModal = createAction("logoProduction/closeConfirmDeleteModal");
export const openRejectImageModal = createAction("logoProduction/openRejectImageModal");
export const closeRejectImageModal = createAction("logoProduction/closeRejectImageModal");
export const openUnsavedChangesModal = createAction("logoProduction/openUnsavedChangesModal");
export const closeUnsavedChangesModal = createAction("logoProduction/closeUnsavedChangesModal");
export const openDeleteBrandModal = createAction("logoProduction/openDeleteBrandModal");
export const closeDeleteBrandModal = createAction("logoProduction/closeDeleteBrandModal");
export const openArchiveBrandModal = createAction("logoProduction/openArchiveBrandModal");
export const closeArchiveBrandModal = createAction("logoProduction/closeArchiveBrandModal");
export const openUnArchiveBrandModal = createAction("logoProduction/openUnArchiveBrandModal");
export const closeUnArchiveBrandModal = createAction("logoProduction/closeUnArchiveBrandModal");
export const createBrandOpenModal = createAction("logoProduction/createBrandOpenModal");
export const createBrandCloseModal = createAction("logoProduction/createBrandCloseModal");
//End Action for live platform modal
//////////////////End of live platform
