import React, { useState } from "react";
import BackBtn from "../../components/Template/BackBtn";
import TableComponent from "../../components/TableComponent/TableComponent";
import ProcessLogTableColumns from "../../components/ImageCollectionComponent/ProcessLogTableColumns";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store";
import { fetchPaginateProcessLog, fetchProcessLog, resetProcessLogTable, updateRequestBodyForProcessLog } from "../../redux/ImageCollection/ImageCollectionAction";
import { useAppDispatch } from "../../redux/useAppDispatch";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

function ProcessLogPage(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const processLogPageState = useSelector((state: RootState) => state.imageCollectionState.processLogPageState);
  const urlDivided = location.pathname.split("/");
  const brandName = location.state.record ? location.state.record.name : "";
  const [firstFetch, setFirstFetch] = useState(false);
  const brandId = Number(urlDivided[urlDivided.length - 1]);
  const dispatch = useAppDispatch();
  const backActionClick = () => {
    navigate("/image-collection");
  };
  const handleTableSorting = (sorter: any) => {
    const isAscend = (order: string) => {
      if (order === "ascend") {
        return true;
      }
      return false;
    };
    const newSortingRequestBody = {
      ...processLogPageState.currentRequestBody,
      sortColumn: sorter.field,
      isAsc: isAscend(sorter.order),
    };
    dispatch(resetProcessLogTable());
    dispatch(updateRequestBodyForProcessLog(newSortingRequestBody));
    dispatch(fetchProcessLog({}));
  };

  useEffect(() => {
    // dispatch(updateRequestBodyForImageCollection({ ...imageCollectionPageState.currentRequestBody, batchId: imageCollectionPageState.currentBatchId }));
    const fetchBProcessLog = async () => {
      dispatch(updateRequestBodyForProcessLog({ ...processLogPageState.currentRequestBody, brandId: brandId }));
      await dispatch(fetchProcessLog({}));
      setFirstFetch(true);
    };
    fetchBProcessLog();

    return () => {
      dispatch(resetProcessLogTable());
      setFirstFetch(false);
    };
  }, []);
  return (
    <div className="body-container pt-3 h-100 gap-3">
      <div className="d-flex align-items-center gap-3">
        <BackBtn
          backActionClick={() => backActionClick()}
          backPath="/live"
        />
        <h3>Process Logs - {brandName}</h3>
      </div>
      <TableComponent
        columns={ProcessLogTableColumns}
        dataSource={processLogPageState.data}
        pagination={processLogPageState.pagination}
        handleTableSorting={handleTableSorting}
        loading={processLogPageState.loading}
        fetchPaginatedData={() => fetchPaginateProcessLog({})}
        tableKey="imageVerificationTableKey"
      />
    </div>
  );
}

export default ProcessLogPage;
