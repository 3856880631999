import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { del, get, post, put } from "../../services/API/ApiClient";
import { DefaultTenantsBodyType } from "../../constant/logoProductionType";
import { openNotification, openStaticErrorNotification, openStaticErrorNotificationForCreateBrand } from "../../components/Template/NotificationComponent";
import { notification } from "antd";
////////////////Start of live platform action
export const fetchTenants = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchTenant", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().TenantsState.currentRequestBody,
    };

    const response = await post("/search", "LogoCloudTenants", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const fetchPaginateTenants = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchPaginateTenants", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    const reqBody = {
      ...getState().TenantsState.currentRequestBody,
      pageNum: getState().TenantsState.pagination.page,
    };
    const response = await post("/search", "LogoCloudTenants", reqBody);
  

    return {
      data: response.data.data,

      hasMore: response.data.data.length > 0,
    };
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const searchTenants = createAsyncThunk<any, { searchKeyword: string }, { state: RootState }>("logoProduction/searchTenants", async (args, { getState, dispatch }) => {
  dispatch(resetTenantsTable());
  dispatch(updateTenantsRequestBody({ ...getState().TenantsState.currentRequestBody, keyword: args.searchKeyword }));
  dispatch(fetchTenants({}));
});
export const fetchRefreshTenants = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchRefreshTenants", async (args, { getState, dispatch, rejectWithValue }) => {
  const currentDataLength = getState().TenantsState.data.length === 0 ? 30 : getState().TenantsState.data.length;
  try {
    const reqBody = {
      ...getState().TenantsState.currentRequestBody,
      pageSize: Math.ceil(currentDataLength / 30) * 30,
    };

    const response = await post("/search", "LogoCloudTenants", reqBody);
    return response.data;
  } catch (e) {
    return rejectWithValue(e);
  }
});
export const autoRefreshTenants = createAsyncThunk<any, any, { state: RootState }>("logoProduction/autoRefreshTenants", async (args, { getState, dispatch, rejectWithValue }) => {
  if (getState().TenantsState.autoRefreshState.currentIntervalId) {
    clearInterval(getState().TenantsState.autoRefreshState.currentIntervalId);
  }
  if (getState().TenantsState.autoRefreshState.autoRefresh) {
    const intervalId = setInterval(() => {
      dispatch(fetchRefreshTenants({}));
    }, 5000);
    dispatch(setTenantsAutoRefresh({ intervalId }));
  }
});
//Start the generic action for Tenants
export const resetTenantsTable = createAction("logoProduction/resetTenantsTable");
export const updateTenantsRequestBody = createAction<DefaultTenantsBodyType>("logoProduction/updateTenantsRequestBody");
export const stopTenantsAutoRefresh = createAction("logoProduction/stopTenantsAutoRefresh");
export const switchTenantsAutoFresh = createAction<{ autoRefresh: boolean }>("logoProduction/switchTenantsAutoFresh");
export const setTenantsAutoRefresh = createAction<{ intervalId: NodeJS.Timer }>("logoProduction/setTenantsAutoRefresh");
//End the generic action for Tenants


//Modal tenants
export const tenantsOpenModal = createAction("logoProduction/tenantsOpenModal");
export const tenantsCloseModal = createAction("logoProduction/tenantsCloseModal");




//TenantsDetailsFetch
export const fetchTenantsDetail = createAsyncThunk<any, any, { state: RootState }>("logoProduction/fetchTenantsDetail", async (tenantId, { getState, dispatch, rejectWithValue }) => {
  try {
    const response = await get(`/${tenantId}`, "LogoCloudTenants");
    return { tenantDetail: response.data };
    
  } catch (e) {
    return rejectWithValue(e);
  }
});

//Edit Modal Changes Save Button Enable
export const setIsEditedForTenantModal = createAction<boolean>(
  "logoProduction/setIsEditedForTenantModal"
);


//Save Tenant
export const saveTenant = createAsyncThunk<any, { tenantDetail: any;  }, { state: RootState }>("logoProduction/saveBrandDetail", async (args, { getState, dispatch, rejectWithValue }) => {
  try {
    notification.destroy();
    const response = await post(`/${args.tenantDetail.id}`, "LogoCloudTenants", { ...args.tenantDetail });
    openNotification("Tenant Details Saved", `Tenant details for '${args.tenantDetail.displayName}' has been saved successfully,`, "top");
   
    return response.data;
    
  } catch (e: any) {

    openStaticErrorNotification("Failed to save Tenant details.", e.response.data.detailedMessages, "top");
    return rejectWithValue(e);
  }
});